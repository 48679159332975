.form {
  width: 100%;
}

.input_block {
  width: 100%;
}

.input_wrapper {
  display: flex;
  margin-top: 15px;
}

.input {
  width: 55%;
}

.input_wrapper > button {
  width: 45%;
}

.input {
  min-width: 70%;
  display: flex;
  font-size: 1rem;
  padding: 10px;
  height: 54px;
  width: 100%;
}

.form_comment {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #fff;
  position: absolute;
}

.form_ssylki {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .input {
    min-width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .form {
    width: 100%;
    margin-top: 30px;
  }

  .input_wrapper {
    justify-content: space-between;
  }

  .form_comment {
    display: none;
  }

  .input_wrapper {
    flex-direction: column;
  }

  .input {
    margin-bottom: 10px;
  }

  .input_wrapper > button {
    width: 100%;
  }
}
