.privacy_content_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    z-index: 4;
}

.privacy_content_wrapper.opened {
    opacity: 1;
    visibility: visible;
    background-color: rgba(0,0,0, .70);
}

.privacy_content {
    position: absolute;
    width: 85vw;
    background-color: #fff;
    border-radius: 16px;
    z-index: 5;
    padding: 20px;
    margin: 50px 0;
}

.privacy_content > button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 25px;
    color: rgb(240, 160, 11);
    transition: all .3s ease;
}

.privacy_content > button:hover {
    color: black;
}

.privacy_policy > strong {
    text-decoration: underline;
    cursor: pointer;
}

.form_comment > strong {
    text-decoration: underline;
    cursor: pointer;    
}