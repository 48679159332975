/* header */
@media screen and (max-width: 576px) {
    .first_screen_heading {
        display: none;
    }

    .credit_section_inputs {
        display: block;
    }

    .credit_section_inputs > * {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .credit_section_inputs > button {
        margin-left: 0 !important;
    }

    .gifts_list {
        display: block;
    }

    .gifts_item {
        width: calc(100% - 5%);
        justify-content: center;
    }

    .contacts_list {
        width: 100%;
    }

    .contacts_map {
        width: 100%;
        height: 500px;
        margin-top: 15px;
    }

    .map {
        height: 100%;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .header_container {
        max-width: 100%;
    }

    .header_menu {
        display: none;
    }

    .header_bid_btn {
        display: none;
    }

    .header_burger {
        display: block;
    }

    .header_wrapper > div:nth-child(1) {
        width: 70%;
    }

    .header_phone {
        margin-right: 0;
    }

    .timer_wrapper {
        flex-direction: column;
        align-items: center;
    }

    .timer_wrapper > div {
        width: 100%;
    }

    .timer {
        margin-right: 0;
    }

    .timer_section_bg {
        background-color: #f2f2f2;
        color: #000;
        background-image: none;
    }

    .form_comment {
        display: block;
    }

    .all_swiper_wrapper {
        width: 100%;
    }

    .thumb_swiper {
        width: 100% !important;
        height: auto;
    }

    .thumb_swiper > .swiper-wrapper {
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 60px;
        margin-bottom: 10px;
    }

    .post_advantages {
        display: flex;
        flex-wrap: wrap;
    }

    .post_advantages_item {
        width: 50%;
        margin-bottom: 10px;
    }

    .reasons_gifs_wrapper {
        width: 50px;
        height: 50px;
        border: none;
    }

    .reasons > div {
        flex-basis: 50% !important;
    }

    .choose_car_img {
        width: 33.3%;
        height: auto;
        padding: 20px 0;
    }

    .header_wrapper.not_mobile {
        height: 100px;
        overflow: hidden;
    }
}

@media screen and (max-width: 992px) {
    .header_adress {
        order: 2;
    }

    .header_adress > span:last-child {
        display: block;
    }

    .header_phone {
        margin-top: 15px;
        order: 2;
    }

    .main_menu_item {
        font-size: 12px;
    }

    .main_swiper > .swiper-wrapper {
        width: 350px;
        height: 300px;
    }

    .reasons > div {
        flex-basis: 33.3%;
    }

    .choose_first_block {
        flex-direction: column;
    }

    .choose_select_wrapper {
        width: 100%;
    }

    .choose_car_equipment {
        flex-wrap: wrap;
    }

    .choose_btns {
        width: 100%;
    }

    .choose_btns > button {
        width: 100%;
    }

    .gifts_list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .gifts_item {
        flex-basis: 45%;
        margin: 0 2.5%;
        margin-bottom: 2.5%;
    }

    .offer_input_block > * {
        max-width: 100%;
    }

    .credit_section_wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .timer_value {
        padding: 0 15px;
    }
}