.modal {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.modalContent {
    display: block;
    max-width: 380px;
    background-color: #fff;
    border-radius: 12px;
    padding: 30px;
}

.modalContent > h3 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
}

.close {
    text-align: end;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 700;
}
