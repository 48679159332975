.btn {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 15px 20px;
    border: 0;
    font-size: 1rem;
    color: #fff;
    background-color: #05141f;
    transition: background-color .15s ease-in-out;
    white-space: nowrap;
    position: relative;
}

.btn:hover {
    background-color: #164668;
    border-color: #164668;
}

.white_btn {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 15px 20px;
    border: 0;
    font-size: 1rem;
    border: 2px solid #222;
    color: #222;
    background-color: #fff;
    transition: all .15s ease-in-out;
}

.white_btn:hover {
    background-color: #164668;
    color: #fff;
}


.gray_btn {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    padding: 15px 20px;
    border: 0;
    font-size: 1rem;
    border: 2px solid #164668;
    color: #164668;
    background-color: #fff;
    transition: all .15s ease-in-out;
}


.gray_btn:hover {
    background-color: #164668;
    color: #fff;
    border: 2px solid #164668;
}

  